<template>
    <b-container class="new-password" fluid>
        <b-container fluid>
            <b-form class="form justify-content-center" @submit.prevent="submitForm">
                <b-row>
                    <b-col lg="12" class="mt-2 mb-4">
                        <b-img
                            :src="branding.primaryLogoUrl"
                            class="logo"
                            alt=" "
                        />
                    </b-col>
                    <b-col lg="12">
                        <span class="title">{{ $t('newPassword.title') }}</span>
                    </b-col>
                </b-row>
                <hr class="hr">
                <b-row v-if="isEmailRegisterRoute()" class="vertical-align">
                    <b-col>
                        <label class="labels">{{ $t('newPassword.email') }}</label>
                        <b-form-input
                            v-model="email"
                            :placeholder="$t('newPassword.fillYourEmail')"
                            class="input-confirm-password"
                            type="email"
                        />
                    </b-col>
                </b-row>
                <b-row class="mt-4 vertical-align">
                    <b-col>
                        <label class="labels">{{ $t('newPassword.passwordLabel') }}</label>
                        <b-form-input
                            v-model="password"
                            :placeholder="$t('newPassword.fillYourPassword')"
                            class="input-password"
                            type="password"
                        />
                    </b-col>
                </b-row>
                <b-row class="mb-3">
                    <b-col class="hint-text">
                        <span>{{ $t('newPassword.passwordHint') }}</span>
                    </b-col>
                </b-row>
                <b-row class="vertical-align mb-5">
                    <b-col>
                        <label class="labels">{{ $t('newPassword.confirmPasswordLabel') }}</label>
                        <b-form-input
                            v-model="confirmPassword"
                            :placeholder="$t('newPassword.fillConfirmPasswordAgain')"
                            class="input-confirm-password"
                            type="password"
                        />
                    </b-col>
                </b-row>
                <b-row
                    v-if="branding.termsAndGdprPdfUrl"
                    class="vertical-align"
                >
                    <b-col>
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="acceptedAllTerms"
                            name="checkbox-1"
                            class="custom-check-box"
                            :value="true"
                            :unchecked-value="false"
                        >
                            <span class="approve-text">
                                {{ $t('newPassword.checkbox[0]') }}
                            </span>
                            <a
                                :href="branding.termsAndGdprPdfUrl"
                                target="_blank"
                                class="terms-text"
                            >{{ $t('newPassword.checkbox[1]') }}</a>
                            <span
                                v-if="branding.contestPdfUrl"
                                class="approve-text"
                            >
                                {{ $t('newPassword.checkbox[2]') }}
                            </span>
                            <a
                                v-if="branding.contestPdfUrl"
                                :href="branding.contestPdfUrl"
                                target="_blank"
                                class="terms-text"
                            >{{ $t('newPassword.checkbox[3]') }}</a>
                            <span
                                v-if="branding.contestPdfUrl"
                                class="approve-text"
                            >
                                {{ $t('newPassword.checkbox[4]') }}
                            </span>
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-button
                            type="submit"
                            variant="primary"
                            class="button"
                            :disabled="(!acceptedAllTerms && !!branding.termsAndGdprPdfUrl) || emailActivationSent"
                        >
                            {{ $t('newPassword.buttonText') }}<font-awesome-icon :icon="[ 'fas', 'paper-plane' ]" class="ml-2" />
                        </b-button>
                    </b-col>
                </b-row>
                <b-row class="vertical-align mt-2 mb-2">
                    <b-col>
                        <div class="wrong-text mt-2">
                            <div v-if="emailActivationSent" class="email-sent">{{ $t('newPassword.emailMessage') }}</div>
                            <div v-if="passwordPolicyFail">{{ $t('newPassword.emptyPassword') }}</div>
                            <div v-if="failedResponse">{{ failedResponse }}</div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div class="back">
                            {{ $t('newPassword.ifYouAlreadyRegistered') }}
                            <router-link to="/login" class="back-link">
                                {{ $t('newPassword.here') }}
                            </router-link>
                        </div>
                    </b-col>
                </b-row>
            </b-form>
        </b-container>
    </b-container>
</template>

<script>
    import config from '@/mixins/config'
    import passwordService from '@/api/password'
    export default {
        mixins: [ config ],
        data() {
            return {
                email: null,
                password: null,
                confirmPassword: null,
                acceptedAllTerms: false,
                acceptedGDPR: true,
                failedResponse: null,
                emailActivationSent: false,
                passwordPolicyFail: false,
                errorMap: {
                    EMPTY_PASSWORD: this.$t('newPassword.emptyPassword'),
                    NOT_COMPLIANT_PASSWORD: this.$t('newPassword.notCompliantPassword'),
                    PLAYER_EXISTS: this.$t('newPassword.playerExists'),
                    PLAYER_NOT_EXISTS_WITH_INVITE_CODE: this.$t('newPassword.playerNotExistsWithInviteCode'),
                    PLAYER_ALREADY_REGISTERED: this.$t('newPassword.playerAlreadyRegistered'),
                    PASSWORDS_NOT_MATCH: this.$t('newPassword.passwordsNotMatch'),
                    EMPTY_EMAIL: this.$t('newPassword.emptyEmail'),
                    INVALID_EMAIL: this.$t('newPassword.invalidEmail'),
                    PLAYER_NOT_ACCEPT_GDPR: this.$t('newPassword.playerNotAcceptGDPR')
                }
            }
        },
        computed: {
            branding() {
                return this.$store.getters.getBranding
            }
        },
        methods: {
            isPasswordValid(password) {
                let regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/
                return regex.test(password)
            },
            isEmailRegisterRoute() {
                return this.$route.name === 'Email Register'
            },
            isEmailValid(email) {
                let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return regex.test(email)
            },
            submitForm() {
                if (this.isEmailRegisterRoute() && !this.isEmailValid(this.email)) {
                    this.failedResponse = this.errorMap['INVALID_EMAIL']
                    return
                }
                if (!this.isPasswordValid(this.password)) {
                    this.passwordPolicyFail = true
                    setTimeout(() => {
                        this.passwordPolicyFail = false
                    }, 5000)
                    return
                }

                if (this.password !== this.confirmPassword) {
                    this.failedResponse = this.errorMap['PASSWORDS_NOT_MATCH']
                    setTimeout(() => {
                        this.failedResponse = false
                    }, 5000)
                    return
                }
                if (!this.acceptedAllTerms && this.branding.termsAndGdprPdfUrl) {
                    this.passwordPolicyFail = false
                    return
                }

                if (this.$route.params.resetToken) {
                    passwordService.newPassword(this.password, this.$route.params.resetToken).then(() => {
                        this.$store.dispatch('logout')
                        this.$router.push({ path: '/login' })
                    }, (error) => { this.handleError(error) }
                    )
                }

                if (this.$route.params.inviteCode) {
                    passwordService.invitationRegister({ password: this.password, acceptedAllTerms: this.acceptedAllTerms }, this.$route.params.inviteCode)
                        .then((res) => {
                            this.$store.commit('login', res.data.token)
                            this.$router.push({ path: '/home' })
                        }).catch((error) => {
                            this.handleError(error)
                        })
                }

                if (this.isEmailRegisterRoute()) {
                    passwordService.register({ email: this.email, password: this.password, acceptedAllTerms: this.acceptedAllTerms }).then(() => {
                        this.emailActivationSent = true
                    }, (error) => { this.handleError(error) })
                }
            },
            handleError(error) {
                this.failedResponse = this.errorMap[error.response.data.code]
                setTimeout(() => {
                    this.failedResponse = null
                }, 5000)
            }
        }
    }
</script>

<style lang="scss" scoped>
.title {
    font-weight: 700;
    color: var(--white);
    font-size: 1.875rem;
}

.hr {
    background: var(--white);
}

.custom-check-box {
    text-align: left;
}

.approve-text {
    color: var(--white);
}

.terms-text {
    font-weight: 700;
    color: var(--white);
    text-decoration: none;
}

.new-password {
    padding: 2.5rem 0;
    height: 100%;
    margin: 0 auto;
    max-width: 800px;
    overflow: auto;
}

.hint-text {
    color: var(--white);
    font-size: 0.75rem;
    line-height: 1.1;
    margin-top: 0.15rem;
    text-align: left;
}

.labels {
    font-weight: 700;
    font-size: 1rem;
    float: left;
}

.input-password {
    font-weight: 700;
    font-size: 0.875rem;
}

.input-confirm-password {
    font-weight: 700;
    font-size: 14px;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.form {
    max-width: 500px;
    padding: 2rem;
    margin: 0 auto;
    border-radius: 5px;
    border: 1px solid var(--white);
    background-color: var(--login-forms-background);
    color: var(--white);
}

.wrong-text {
    font-weight: 700;
    font-size: 14px;
    color: var(--form-error-color);
}

.email-sent {
    font-weight: 700;
    font-size: 14px;
    color: var(--black);
}

.logo {
    width: 191px;
    vertical-align: middle;
}

.button {
    padding: 4% 27%;
    white-space: nowrap;
    border-radius: 17px;
    border: 1px solid var(--white);
    background-color: var(--white);
    display: block;
    width: 100%;
    margin-top: 0.5rem;
    color: var(--login-forms-background);
    font-weight: 700;
    font-size: 18px;
}

.button:hover:enabled {
    color: var(--black);
}

.question {
    margin-top: 10%;
}

.guideline {
    margin-top: 10%;
}

.back {
    text-decoration: none;
    color: var(--white);
}

.back-link {
    color: var(--white);
    font-weight: 700;
}

@media screen and (min-width: 768px) {
    .form {
        padding: 3rem;
    }
}
</style>
